import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "redux/store";
import * as Mui from "@material-ui/core";
import * as MuiLab from "@material-ui/lab";
import * as Icons from "react-feather";
import { FeatherIcon } from "component/shared/feather-icon";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { TopMenu } from "./top-menu";
import { SidebarMenu } from "./sidebar-menu";
import { getViewType } from "redux/selector";
import { getUserUnsafe } from "redux/selector";
import { getIntegrationByType } from "redux/selector";
import { styles } from "./style";
import { AccountMenu } from "./account-menu";
import { QuickSearchDialog } from "./quick-search-dialog";
import { NotPermittedPage } from "component/page/not-permitted";
// @ts-ignore
import Favicon from "react-favicon";
import { MobileAppDialog } from "./mobile-app-dialog";
import { IntegrationType } from "type/integration-type";
import { urls } from "routes/urls";
import { RouterLinkWrapper } from "component/shared/router-link-wrapper";

const mapStateToProps = (state: RootState) => {
	return {
		user: getUserUnsafe(state),
		viewType: getViewType(state),
		emailCalenderIntegration: getIntegrationByType(state, IntegrationType.EMAIL_CALENDAR),
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

interface Props extends
	ReturnType<typeof mapStateToProps>,
	ReturnType<typeof mapDispatchToProps>,
	Mui.WithStyles<typeof styles> {
	title: string;
	header?: React.ReactNode;
	iframe?: React.ReactNode;
	permitted?: boolean | null;
	fullScreen?: boolean;
}

interface State {
	toggleLeft: boolean;
	quickSearchOpen: boolean;
}

class Component extends React.Component<Props, State> {

	private hideNav = window !== window.parent && !document.referrer.startsWith("https://manage.wix.com/");

	public constructor(props: Props) {
		super(props);
		this.state = {
			toggleLeft: false,
			quickSearchOpen: false,
		};
	}

public componentDidMount() {
  document.addEventListener("keydown", this.openSearch);
  
  // Inject CSS into <head>
  const style = document.createElement("style");
  style.id = "reseller-css";
  style.innerHTML = this.resellerCss;
  document.head.appendChild(style);
}

public componentWillUnmount() {
  document.removeEventListener("keydown", this.openSearch);
  
  // Remove the injected CSS when the component unmounts
  const style = document.getElementById("reseller-css");
  if (style) {
    style.remove();
  }
}


	private openSearch = (event: KeyboardEvent) => {
		const { user } = this.props;
		if (event.ctrlKey && event.key === "f" && user && !user.permissions.oneCrm) {
			event.preventDefault();
			const { quickSearchOpen } = this.state;
			this.setState({ quickSearchOpen: !quickSearchOpen });
		}
	}

	public toggleLeft() {
		const toggleLeft = !this.state.toggleLeft;
		this.setState({ toggleLeft });
	}

	private get resellerCss() {
		const { user } = this.props;
		if (user && user.reseller.css) {
			let result = user.reseller.css;
			result = result.replace(/#ih-cpnav-/g, ".ihf-");
			return result;
		}
		return "";
	}

	public render() {
		const { classes, children, permitted, title, header, iframe, user, viewType, emailCalenderIntegration } = this.props;
		const fullScreen = this.props.fullScreen !== false;
		const { quickSearchOpen } = this.state;
		if (!user) {
			throw new Error();
		}
		if (permitted === false) {
			return <NotPermittedPage />;
		}

		return (
			<> 
				<MobileAppDialog user={user} />
				<Helmet title={`${title} | ${user.reseller.name} Control Panel`} />
				<Favicon url={[user.reseller.direct ? "favicon.ico" : "blank.ico"]} />
				{viewType.client &&
					<Helmet>
						<style type="text/css">
							{this.resellerCss}
						</style>
					</Helmet>
				}
				{quickSearchOpen && (
					<QuickSearchDialog
						open={quickSearchOpen}
						onClose={() => this.setState({ quickSearchOpen: false })}
					/>
				)}
				
				<div>

					{emailCalenderIntegration && emailCalenderIntegration.errored && (
						<MuiLab.Alert severity="error">
							Uh oh! Your {emailCalenderIntegration.label} integration is not working correctly.
							{" "}
							<Mui.Link component={RouterLinkWrapper} to={urls.settingsIntegrations}>
								Update it here.
							</Mui.Link>
						</MuiLab.Alert>
					)}
					
					<div className={classes.layout}>
						{!this.hideNav && (
							<div
								className={classNames(
									classes.left,
									this.state && this.state.toggleLeft ? "show" : null,
								)}
							>
								<div
									className={classes.leftTop}
									style={user.reseller.logo && !user.reseller.direct ? {
										backgroundColor: "#fff",
									} : {}}
								>
									<div className={classes.logoWrapper}>
									{ user.controlPanelUrl ? (
											<img className={classes.logo} src={user.controlPanelUrl} alt="" />
										) : user.reseller.logo ? (
											<img className={classes.logo} src={user.reseller.logo} alt="" />
										) : (
											<div className={classes.logoMissing}>{user.reseller.name}</div>
										)
									}
									</div>
									<Mui.Hidden mdUp>
										<Mui.IconButton
											size="small"
											color="inherit"
											onClick={() => this.toggleLeft()}
										>
											<FeatherIcon>
												<Icons.ArrowLeft fontSize="small" />
											</FeatherIcon>
										</Mui.IconButton>
									</Mui.Hidden>
								</div>
								<div className={classes.leftBottom}>
									<div className={classes.namePlate}>
										<div className={classes.line1}>{user.company}</div>
										<div className={classes.line2}>#{user.clientId}</div>
									</div>
									<SidebarMenu user={user} viewType={viewType} />
								</div>
								<div className="backdrop" onClick={() => this.toggleLeft()} />
							</div>
						)}
						<div className={classes.right}>
							{!this.hideNav && (
								<Mui.AppBar position="sticky" className={classes.navBar}>
									<Mui.Toolbar>
										<Mui.Hidden mdUp>
											<Mui.IconButton onClick={() => this.toggleLeft()}>
												<FeatherIcon>
													<Icons.Menu />
												</FeatherIcon>
											</Mui.IconButton>
										</Mui.Hidden>
										<Mui.Hidden smDown>
											<TopMenu user={user} />
										</Mui.Hidden>
										<div className={classes.flexGrow} />
										{!user.permissions.oneCrm && (
											<Mui.IconButton
												onClick={() => this.setState({ quickSearchOpen: true })}
											>
												<FeatherIcon>
													<Icons.Search />
												</FeatherIcon>
											</Mui.IconButton>
										)}
										<AccountMenu />
									</Mui.Toolbar>
								</Mui.AppBar>
							)}
							
							<div className={classes.content}>
							
								{header && (
									<Mui.AppBar position="relative" className={classes.header}>
										<Mui.Toolbar className={classes.toolbar}>
												<Mui.Grid item xs={10}>
													{header}
												</Mui.Grid>
										</Mui.Toolbar>
									</Mui.AppBar>
								)}
								
								{iframe && <div className={classes.iframeContainer}>{iframe}</div>}
								
								{children && <div className={classNames(
									classes.mainContainer,
									(!fullScreen) ? classes.fixedWidthContent : null
									)}>
										{fullScreen && children}
										{!fullScreen && <div className={classes.fixedWidthChild}>
											{children}
										</div>}
								</div>}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export const DashboardLayout = Mui.withStyles(styles)(
	connect(mapStateToProps, mapDispatchToProps)(Component)
);